import React from 'react'

function Footer() {
  return (
    <footer className="bg-gray text-light pt-4">
      <div className="container justify-center flex-row">
      <div className='row justify-center'>
          <a className='ml-2 mr-2' href="/privacy">
            <p>
              Privacy Policy
            </p>
          </a>
          <a className='ml-2 mr-2' href="/terms">
            <p>
              Terms of Service
            </p>
          </a>
        </div>
        <div className="row justify-center">
            <img alt="Image" src="/branches-logo.png" className=" mr-3 mb-4 block h-8 w-auto" />
            <p className="text-muted">
              © 2023 Branches   . All rights reserved.
            </p>

        </div>
        {/*end of row*/}
      </div>
      {/*end of container*/}
    </footer>  )
}

export default Footer;