import React, { useState } from 'react'
import './LandingPage.css';
import Footer from './components/Footer';

function LandingPage() {
  
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [section, setSection] = useState('1');
  const url = "https://branches-server.herokuapp.com"

  const onSubmit = async (e) => {
    e.preventDefault();
    if (email === '') {
      setEmailError('Email cannot be empty');
      return;
    } 
    if (!email.includes('@')) {
      setEmailError('Email must contain @');
      return;
    }
    try{
      const response = await fetch(`${url}/waitlist`, {
        method: "POST",
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "email": email }),
      });
      if(response.ok){
        setEmailError('Success! You will be notified when we launch.');
      } else {
        setEmailError('Something went wrong. Please try again.');
      }
    } catch(error){
      console.log(error);
    }
    
  }


  return (
    <div className="main-container">
    <section style={{backgroundColor: '#598c6e'}} className="space-lg">
      <img style={{color: 'white'}} alt="Image" src="assets/img/graphic-bg-clouds-2.png" className="bg-image" />
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-md-6 col-lg-5 section-intro">
            <h1 style={{color: 'white'}}>Branches</h1>
            <h1 style={{color: 'white'}} className="display-3">Building community in your Apartment</h1>
            <span style={{color: 'white'}} className="lead">
              A modern way to connect with your neighbors and form groups based on your shared interests.
            </span>
          </div>
          {/*end of col*/}
          <div className="col-12 col-md-6 col-lg-5">
            <div style={{justifyContent: 'center', display: 'flex'}}>
                <a style={{justifyContent: 'center'}} href="https://apps.apple.com/us/app/branches-community/id6449562979?platform=iphone" target="_blank">
                  <img style={{width: '150px'}} src="/app_store_white.svg" alt="Download on the App Store"/>
                </a>
            </div>
          </div>
        </div>
        {/*end of row*/}
      </div>
      {/*end of container*/}
    </section>
    {/*end of section*/}
    <section>
      <div className="container">
        <div className="row justify-content-center text-center section-intro">
          <div className="col-12 col-md-9 col-lg-8">
            <span className="title-decorative">Meet The Future of Apartment Living</span>
            <h2 className="display-4">Introducing a new way to build relationships</h2>
            <span className="lead">Find and connect with people with common interests in your apartment buildings.</span>
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
        <div className="row justify-content-center">
          <div className="col-md-8 col-sm-10">
            <div className="video-cover rounded">
              <img alt="Image" src="assets/img/undraw_neighbors_ciwb (2).png" className="bg-image"/>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe className="embed-responsive-item" data-src="https://www.youtube.com/embed/oYqvpjKJZkU?autoplay=1&mute=1&showinfo=0&rel=0" allowFullScreen />
              </div>
            </div>
            {/*end of video cover*/}
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
        <div className="row justify-content-center text-center section-outro">
          <div className="col-lg-4 col-md-5">
            <h6>Friends and family just next door</h6>
            <p>Befriend your neighbors based on your shared interests</p>
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
      </div>
      {/*end of container*/}
    </section>
    {/*end of section*/}
    <section>
      <div className="container">
        <div className="row justify-content-center text-center section-intro">
          <div className="col-12 col-md-9 col-lg-8">
            <span className="title-decorative">Welcome Home</span>
            <h2 className="display-4">Make your apartment building your community</h2>
            <span className="lead">Have your close friends only an elevator ride away</span>
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
        <div className="row justify-content-around">
          <div className="col-md-5 col-7 mb-4">
            <div className="tab-content">
              {section === '1' ? <div className="tab-pane fade show active" id="content-1" role="tabpanel">
                <img alt="Image" className="img-fluid w-100" src="assets/img/graphic-desktop.svg" />
              </div>
              : section === '2' ?
              <div className="tab-pane fade show active" id="content-2" role="tabpanel">
                <img alt="Image" className="img-fluid w-100" src="assets/img/graphic-fingerprint.svg" />
              </div>
              :
              <div className="tab-pane fade show active" id="content-3" role="tabpanel">
                <img alt="Image" className="img-fluid w-100" src="assets/img/graphic-envelope.svg" />
              </div>}
            </div>
          </div>
          {/*end of col*/}
          <div className="col-lg-5 col-md-8 mb-4">
            <ul className="nav nav-cards" role="tablist">
              <li>
                {
                  section === '1' ? <a className="card active" role="tab" aria-controls="content-1" aria-selected="true">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">1</div>
                      <div className="media-body">
                        <h5>Register with your apartment building</h5>
                        <span>
                          You just need an email address, and the name/address of your building
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                : <a className="card" role="tab" aria-controls="content-1" aria-selected="false" onClick={() => setSection('1')}>
                    <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">1</div>
                      <div className="media-body">
                        <h5>Register with your apartment building</h5>
                        <span>
                          You just need an email address, and the name/address of your building
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              }
              </li>
              <li>
                {
                  section === '2' ? <a className="card active" role="tab" aria-controls="content-2" aria-selected="true">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">2</div>
                      <div className="media-body">
                        <h5>Join/Create groups based on your interests</h5>
                        <span>
                          Anything from "Dog Lovers" to "Book Club" to "Running"
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                : <a className="card" role="tab" aria-controls="content-2" aria-selected="false" onClick={() => setSection('2')}>
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">2</div>
                      <div className="media-body">
                        <h5>Join/Create groups based on your interests</h5>
                        <span>
                          Anything from "Dog Lovers" to "Book Club" to "Running"
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                }
              </li>
              <li>
                {
                  section === '3' ? <a className="card active" role="tab" aria-controls="content-3" aria-selected="true">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">3</div>
                      <div className="media-body">
                        <h5>Interact and befriend your neighbors</h5>
                        <span>
                          Chat with everyone in your apartment building who shares your interests
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
                : <a className="card" role="tab" aria-controls="content-3" aria-selected="false" onClick={() => setSection('3')}>
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="step-circle mr-4">3</div>
                      <div className="media-body">
                        <h5>Interact and befriend your neighbors</h5>
                        <span>
                          Chat with everyone in your apartment building who shares your interests
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              }
              </li>
            </ul>
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
      </div>
      {/*end of container*/}
    </section>

    <section className="bg-white">
      <div className="container">
        <div className="row section-intro">
          <div className="col text-center">
            <h3 className="h1">Don’t wait, download now.</h3>
          </div>
          {/*end of col*/}
        </div>
        {/*end of row*/}
        <div style={{justifyContent: 'center', display: 'flex'}}>
                <a style={{justifyContent: 'center'}} href="https://apps.apple.com/us/app/branches-community/id6449562979?platform=iphone" target="_blank">
                  <img style={{width: '150px'}} src="/app_store.svg" alt="Download on the App Store"/>
                </a>
        </div>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          <img alt="Image" src="branches-full-logo.png" style={{height: '10rem'}} className="mt-5 w-auto justify-center" />
        </div>
      </div>
      {/*end of container*/}
    </section>
    {/*end of section*/}
    <Footer/>
  </div>  )
}

export default LandingPage;