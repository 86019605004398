import React from 'react'
import Footer from './components/Footer';

function EULA() {
  return (
    <>
      <div className="container" style={{margin: 2}}>
        <h1>END USER LICENSE AGREEMENT (EULA) FOR BRANCHES MOBILE APPLICATION</h1>
        <p><strong>Effective Date: June 5, 2023</strong></p>
        <p>PLEASE READ THIS END USER LICENSE AGREEMENT ("EULA") CAREFULLY BEFORE USING THE BRANCHES MOBILE APPLICATION ("APPLICATION"). BY DOWNLOADING, INSTALLING, OR USING THE APPLICATION, YOU AGREE TO BE BOUND BY THE TERMS OF THIS EULA.</p>

        <h2>1. LICENSE</h2>
        <p>1.1 Branches ("Licensor") grants you a revocable, non-exclusive, non-transferable, limited license to download, install, and use the Application for your personal, non-commercial use strictly in accordance with the terms of this EULA.</p>

        <h2>2. ACCEPTABLE USE POLICY</h2>
        <p>2.1 You agree not to use the Application to engage in any form of discrimination based on race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or any other characteristics protected by law.</p>
        <p>2.2 You agree not to engage in or encourage any form of harassment, bullying, defamation, or any form of abusive behavior towards other users of the Application.</p>
        <p>2.3 You agree not to upload, post, transmit, or otherwise distribute any content that is obscene, offensive, hateful, inflammatory, or otherwise objectionable.</p>

        <h2>3. CONTENT MODERATION</h2>
        <p>3.1 The Licensor reserves the right to review and remove any content that violates the Acceptable Use Policy as described in section 2 of this EULA. The Licensor also reserves the right to ban users who repeatedly violate this policy.</p>

        <h2>4. INTELLECTUAL PROPERTY</h2>
        <p>4.1 All intellectual property rights in the Application, including but not limited to design, text, graphics, logos, images, software, and source code, are owned by the Licensor, and are protected by copyright, trademark, and other intellectual property laws.</p>

        <h2>5. LIMITATION OF LIABILITY</h2>
        <p>5.1 To the extent permitted by applicable law, the Licensor will not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of or inability to use the Application.</p>

        <h2>6. TERMINATION</h2>
        <p>6.1 The Licensor may terminate this EULA at any time without notice if it finds that you have violated any of the terms of this EULA. Upon termination, you must cease all use of the Application and uninstall it from your device.</p>

        <h2>7. CHANGES TO THIS EULA</h2>
        <p>7.1 The Licensor reserves the right to modify this EULA at any time. Changes will be effective immediately upon posting of the updated EULA on the Application. Your continued use of the Application constitutes your acceptance of such changes.</p>

        <h2>8. GOVERNING LAW</h2>
        <p>8.1 This EULA is governed by and construed in accordance with the laws of the jurisdiction where the Licensor resides, without regard to its conflict of law provisions.</p>

        <h2>9. CONTACT</h2>
        <p>9.1 If you have any questions about this EULA, please contact the Licensor at shashank@brnxs.co.</p>

        <p>By using the Application, you acknowledge that you have read and understood this EULA and agree to be bound by its terms and conditions.</p>

        <p>For and on behalf of<br/>
        Branches</p>

        <p>This document is not a substitute for legal advice. You should consult with a legal professional to ensure compliance with all applicable laws and regulations. The Licensor disclaims all liability for any errors or omissions in this EULA.</p>
      </div>
      <Footer/>
    </>
  )
}

export default EULA;