import logo from './logo.svg';
import './App.css';
import LandingPage from './LandingPage';
import PrivacyPolicy from './PrivacyPolicy';
import Navbar from './components/Navbar';
import PageNotFound from './components/PageNotFound';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import EULA from './EULA';


function App() {
  return (
    <>
    <Navbar/>
    <Router>
      <Routes>
        <Route exact path='/' element={<LandingPage/>}/>
        <Route exact path='/privacy' element={<PrivacyPolicy/>}/>
        <Route exact path='/terms' element={<EULA/>}/>
        <Route path='/404' element={<PageNotFound/>}/>
        <Route path='*' element={<Navigate replace to='/404'/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
