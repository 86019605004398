import React from 'react'
import Footer from './components/Footer';

function PrivacyPolicy() {
  return (
    <>
      <div className="container" style={{margin: 2}}>
        <h1>Branches Privacy Policy</h1>
        <p>Last updated: May 30, 2023</p>
        <p>This Privacy Policy describes how your personal information is collected, used, and shared when you use the Branches mobile application (the "App") owned and operated by Branches ("we", "us", "our").</p>
        <p>By using our App, you agree to the collection and use of information in accordance with this policy. If you do not agree with the terms of this policy, do not download or use the App.</p>

        <h2>1. INFORMATION WE COLLECT</h2>
        <p>Our App collects the following types of information:</p>
        
        <h3>1.1 Personal Identification Information</h3>
        <p>We collect personally identifiable information, such as:</p>
        <ul>
            <li>Your full name.</li>
            <li>Email address.</li>
            <li>Profile picture.</li>
        </ul>
        
        <h3>1.2 Non-Personal Identification Information</h3>
        <p>We may collect non-personal identification information about Users whenever they interact with our App. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our App, such as the operating system and the Internet service providers utilized and other similar information.</p>
        
        <h3>1.3 Chat Data</h3>
        <p>We store all chat information in our database for the purpose of facilitating communication within the App.</p>
        <p>In future, we may also collect your mobile number for account verification purposes and to improve our services. You will be notified of such changes and they will be updated in this Privacy Policy.</p>

        <h2>2. HOW WE USE YOUR INFORMATION</h2>
        <p>We use the information we collect from you for the following primary purposes:</p>
        <ul>
            <li>To provide and maintain our service.</li>
            <li>To personalize your experience.</li>
            <li>To provide customer support.</li>
            <li>To gather analysis or valuable information so that we can improve our service.</li>
            <li>To monitor the usage of our service.</li>
            <li>To detect, prevent and address technical issues.</li>
        </ul>

        <h2>3. HOW WE SHARE YOUR INFORMATION</h2>
        <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.</p>
        
        <h2>4. SECURITY</h2>
        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        
        <h2>5. CHANGES TO THIS PRIVACY POLICY</h2>
        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
        
        <h2>6. CONTACT US</h2>
        <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at:</p>
        <p>shashank@brnxs.co</p>
        <p>By using Branches, you consent to our Privacy Policy and agree to its terms.</p>

      </div>
      <Footer/>
    </>
  )
}

export default PrivacyPolicy;